// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
//require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
import jquery from 'jquery';
window.$ = window.jquery = jquery;
import "bootstrap";
    //import("./checkout") // webpack will load this JS async
    import "@fortawesome/fontawesome-free/js/all";
    import "@fortawesome/fontawesome-free/css/all";
    import ('./cp')
//import IMask from '../../../node_modules/imaskjs/dist/imask.js';

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
function normalizeSlideHeights() {
    $('.carousel').each(function(){
      var items = $('.carousel-item', this);
      // reset the height
      items.css('min-height', 0);
      // set the height
      var maxHeight = Math.max.apply(null, 
          items.map(function(){
              return $(this).outerHeight()}).get() );
      items.css('min-height', maxHeight + 'px');
    })
}
import Rails from "@rails/ujs";
window.Rails = Rails;

$(window).on(
    'load resize orientationchange', 
    normalizeSlideHeights);


 